import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'

hljs.configure({ languages: ['cpp', 'csharp', 'erb', 'glsl', 'java', 'javascript', 'python', 'xml'] })

hljs.highlightAll()

document.addEventListener('turbolinks:load', (event) => {
  document.querySelectorAll('pre').forEach((block) => {
    hljs.highlightElement(block)
  })
})
